import React from "react";
import SongLoopSlider from "../SongLoopSlider/SongLoopSlider";
import "../../assets/styles/LoopSliders.scss";
import { connect } from "react-redux";
import Pizzicato from "pizzicato";
import {
  updateLoopFrequency,
  muteLoop,
  updateSoloLoops,
  setSoloLoop,
} from "../../redux/slices/musicPlayerSlice";

function mapStateToProps(state) {
  const { currentAudioLoops, isPlaying, loopsInfo } = state.musicPlayer;

  return { currentAudioLoops, isPlaying, loopsInfo };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoopFrequency: (payload) => {
      dispatch(updateLoopFrequency(payload));
    },
    muteLoop: (payload) => {
      dispatch(muteLoop(payload));
    },
    updateSoloLoops: (payload) => {
      dispatch(updateSoloLoops(payload));
    },
    setSoloLoop: (payload) => {
      dispatch(setSoloLoop(payload));
    },
  };
}

class LoopSliders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      songAudioLoops: [],
    };
  }

  createPizzicatoSound = () => {
    let songsLoops = [];
    for (let i = 0; i < this.props.currentAudioLoops.length; i++) {
      let song;
      song = new Pizzicato.Sound(this.props.currentAudioLoops[i], () => {
        song.loop = true;
        song.addEffect(
          new Pizzicato.Effects.LowPassFilter({
            frequency: this.props.loopsInfo[i].frequency,
          })
        );
      });
      songsLoops.push(song);
    }
    if (songsLoops.length > 0) {
      this.setState({ songAudioLoops: songsLoops });
    }
  };

  componentDidMount() {
    this.createPizzicatoSound();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPlaying !== this.props.isPlaying) {
      if (this.state.songAudioLoops.length > 0) {
        this.setState((state) => {
          state.songAudioLoops.forEach((el) => {
            if (this.props.isPlaying && el && !el.playing) {
              el.play();
            } else if (!this.props.isPlaying && el && el.playing) {
              el.pause();
            }
          });
        });
      }
    }
    if (prevProps.currentAudioLoops !== this.props.currentAudioLoops) {
      this.createPizzicatoSound();
      if (this.props.isPlaying) {
        this.setState((state) => {
          state.songAudioLoops.forEach((el) => {
            el.play();
          });
        });
      }
    }
  }

  toggleMuteLoop = (loopIndex) => {
    let songAudioLoopsCopy = this.state.songAudioLoops;
    let loopsInfoCopy = this.props.loopsInfo;
    let song = songAudioLoopsCopy[loopIndex];
    let muted;
    let soloMode = false;
    for (let i = 0; i < loopsInfoCopy.length; i++) {
      if (loopsInfoCopy[i].solo) {
        soloMode = true;
        break;
      }
    }
    if (!soloMode) {
      if (songAudioLoopsCopy[loopIndex].volume === 1) {
        if (!loopsInfoCopy[loopIndex].solo) {
          song.volume = 0;
        }
      } else if (songAudioLoopsCopy[loopIndex].volume === 0) {
        if (!loopsInfoCopy[loopIndex].solo) {
          song.volume = 1;
        }
      }
      muted = song.volume === 1 ? false : true;
      songAudioLoopsCopy[loopIndex] = song;
      this.props.muteLoop({ loopIndex: loopIndex, muted: muted });
      this.setState({ songAudioLoops: songAudioLoopsCopy });
    }
  };

  updateLoopFrequency = (loopIndex, value) => {
    let songAudioLoopsCopy = this.state.songAudioLoops;
    songAudioLoopsCopy[loopIndex].effects[0].frequency = value;
    this.props.updateLoopFrequency({ frequency: value, loopIndex: loopIndex });
    this.setState({ songAudioLoops: songAudioLoopsCopy });
  };

  setSoloLoop = (loopIndex) => {
    let songAudioLoopsCopy = this.state.songAudioLoops;
    let loopsInfoCopy = this.props.loopsInfo;
    if (loopsInfoCopy[loopIndex].solo) {
      this.props.setSoloLoop({ loopIndex: loopIndex, solo: false });
      songAudioLoopsCopy.forEach((el, index) => {
        if (!loopsInfoCopy[index].muted) el.volume = 1;
      });
    } else {
      this.props.updateSoloLoops({ solo: false });
      songAudioLoopsCopy.forEach((el, index) => {
        if (index !== loopIndex) el.volume = 0;
        else {
          if (!loopsInfoCopy[index].muted) el.volume = 1;
        }
      });
      this.props.setSoloLoop({ loopIndex: loopIndex, solo: true });
    }
    this.setState({ songAudioLoops: songAudioLoopsCopy });
  };

  songLoops = () => {
    return this.state.songAudioLoops && this.state.songAudioLoops.length !== 0
      ? this.state.songAudioLoops.map((el, index) => (
          <SongLoopSlider
            key={index}
            text={index + 1}
            loop={el}
            frequency={this.props.loopsInfo[index].frequency}
            muted={this.props.loopsInfo[index].muted}
            solo={this.props.loopsInfo[index].solo}
            loopIndex={index}
            updateLoopFrequency={this.updateLoopFrequency}
            toggleMuteLoop={this.toggleMuteLoop}
            setSoloLoop={this.setSoloLoop}
          />
        ))
      : null;
  };

  render() {
    return <div className="sliders">{this.songLoops()}</div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoopSliders);
