import React from "react";
import MSlider from "../MSlider/MSlider";
import MButton from "../MButton/MButton";
import mColors from "../../utils/styleVariables";
import "../../assets/styles/SongLoopSlider.scss";
// import PropTypes from "prop-types";

export default function SongLoopSlider(props) {
  const sliderChangeValue = (newValue) => {
    props.updateLoopFrequency(props.loopIndex, newValue);
  };

  return (
    <div className="loop-slider">
      {/** Button Solo loop*/}
      <MButton
        text="S"
        active={props.solo ? true : false}
        backgroundActiveColor={mColors.rose}
        backgroundDisableColor={mColors.darkGray}
        onClick={() => {
          props.setSoloLoop(props.loopIndex);
        }}
      />
      <MSlider
        onSliderChange={sliderChangeValue}
        sliderValue={props.frequency}
      />
      <MButton
        text={props.text.toString()}
        active={props.muted ? false : true}
        backgroundActiveColor={mColors.blue}
        backgroundDisableColor={mColors.darkGray}
        onClick={() => props.toggleMuteLoop(props.loopIndex)}
      />
    </div>
  );
}

// SongLoopSlider.propTypes = {
//   url: PropTypes.string,
// };
