import React from "react";

export default function GiftIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             className={props.className}
             viewBox="0 0 36.507 39.315">
            <g transform="translate(-11 -12.276)">
                <path d="M13,25.276V42.125a4.213,4.213,0,0,0,4.212,4.212h9.829V25.276H13.562A3.75,3.75,0,0,1,13,25.276Z"
                      transform="translate(0.808 5.253)" fill="#109dff"/>
                <path d="M25,25.276V46.338h9.829a4.213,4.213,0,0,0,4.212-4.212V25.276a3.75,3.75,0,0,1-.562,0Z"
                      transform="translate(5.658 5.253)" fill="#109dff"/>
                <path
                    d="M44.151,20.7H41.1a5.5,5.5,0,0,0,.786-2.808,5.616,5.616,0,0,0-5.616-5.616,8.421,8.421,0,0,0-7.021,3.777,8.421,8.421,0,0,0-7.021-3.777,5.616,5.616,0,0,0-5.616,5.616A5.484,5.484,0,0,0,17.4,20.7H14.37A3.383,3.383,0,0,0,11,24.071v1.685a3.384,3.384,0,0,0,3.37,3.37H44.137a3.385,3.385,0,0,0,3.37-3.37V24.113A3.384,3.384,0,0,0,44.151,20.7Zm-21.918,0a2.808,2.808,0,0,1,0-5.616A5.616,5.616,0,0,1,27.849,20.7Zm14.041,0H30.658a5.616,5.616,0,0,1,5.616-5.616,2.808,2.808,0,0,1,0,5.616Z"
                    transform="translate(0 0)" fill="#109dff"/>
            </g>
        </svg>
    );
}
