import React from "react";
import { useSelector } from "react-redux";
import '../../assets/styles/MusicDisplay.scss';

export default function MusicDisplay() {
  const currentSong = useSelector((state) => state.musicPlayer.currentSong);
  const {loading} = useSelector((state) => state.musicPlayer);

  return (
    <div>
      <h1 className="music-display">
        {Object.entries(currentSong).length !== 0 && !loading ? currentSong.name : ""}
      </h1>
    </div>
  );
}
