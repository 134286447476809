import React from "react";
// import PropTypes from "prop-types";
import Logo from "../../assets/images/logo_contact.svg";
import "../../assets/styles/PresentationModal.scss";
import IconButton from "../IconButton/IconButton";
import {ReactComponent as CrossIcon} from "../../assets/images/delete.svg";

export default function PresentationModal(props) {
    return (
        <div className="container">
            <div className="header">
                <IconButton
                    disableSvgHover={true}
                    component={CrossIcon}
                    viewBox="0 0 348.333 348.334"
                    onClick={() => {
                        props.toggleReadyModal();
                    }}
                    fill="black"
                />
            </div>
            <div className="presentation-content">
                <p className="presentation-text presentation-text__primary">
                    DJV Selector présente
                </p>
                <img src={Logo} className="logo-presentation" alt="logo"/>
                <p className="mix-text mix-text__primary">
                    Joue avec les pistes et crée ton mix
                </p>
                <button
                    className="ready-btn"
                    onClick={() => {
                        props.toggleReadyModal();
                    }}
                >
                    Prêt !
                </button>
                <p className="sound-text sound-text__primary">Monte le son !</p>
            </div>
            <p className="copyright-text copyright-text__primary">
                © 2021 Studiometis. Tous droits réservés.
            </p>
        </div>
    );
}
