import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Musiconme from "./Musiconme";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import appStore from "./redux/store";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={appStore.store}>
      <PersistGate loading={null} persistor={appStore.persistor}>
          <Router>
              <Switch>
                  <Route path="/:songName">
                      <Musiconme />
                  </Route>
                  <Route path="/">
                      <Musiconme />
                  </Route>
              </Switch>
          </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
