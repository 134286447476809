import { makeStyles, Slider } from "@material-ui/core";
import React from "react";
import mColors from "../../utils/styleVariables";
import {breakpoints} from "../../utils/constants";

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

const useStyle = makeStyles({
  root: {
    marginTop: 22,
    marginBottom: 22,
    "@media (max-width: 600px)": {
      marginTop: 12,
      marginBottom: 12,
    },
    [mq[0]]: {},
    [mq[1]]: {},
    [mq[2]]: {},
    [mq[3]]: {},
  },
  rail: {
    transform: "scaleX(2.5)",
    color: mColors.lightGray,
    "@media (max-width: 600px)": {
      transform: "scaleX(1.7)",
    },
    [mq[0]]: {},
    [mq[1]]: {},
    [mq[2]]: {},
    [mq[3]]: {
      transform: "scaleX(2.8)"
    },
    [mq[4]]: {
      transform: "scaleX(3.5)",
    }
  },
  track: {
    color: mColors.blue,
    transform: "scaleX(2.5)",
    "@media (max-width: 600px)": {
      transform: "scaleX(1.7)",
    },
    [mq[3]]: {
      transform: "scaleX(2.8)"
    },
    [mq[4]]: {
      transform: "scaleX(3.5)",
    }
  },
  thumb: {
    border: "1.2px solid black",
    transform: "scale(3.1)",
    color: mColors.blue,
    boxShadow: "unset",
    "&::after": {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 10,
      height: 10
    },
    "&:hover": {
      boxShadow: "unset",
    },
    "&:focus-within": {
      boxShadow: "unset",
    },
    "@media (max-width: 600px)": {
      transform: "scale(1.8)",
    },
    [mq[0]]: {},
    [mq[1]]: {},
    [mq[2]]: {},
    [mq[3]]: {
      transform: "scale(3.6)"
    },
    [mq[4]]: {
      transform: "scale(4.1)",
    },
  },
  active: {
    boxShadow: "unset",
    "&:active": {
      boxShadow: "unset",
    },
  },
  focusVisible: {
    boxShadow: "unset",
    "&:active": {
      boxShadow: "unset",
    },
  },

});

export default function MSlider(props) {
  const classes = useStyle(props);

  return (
    <Slider
      classes={{
        root: classes.root,
        rail: classes.rail,
        track: classes.track,
        thumb: classes.thumb,
        focusVisible: classes.focusVisible,
        active: classes.active,
      }}
      orientation="vertical"
      onChange={(ev, value) => props.onSliderChange(value)}
      min={10}
      max={2000}
      step={2}
      value={props.sliderValue}
      defaultValue={props.sliderValue}
      aria-labelledby="vertical-slider"
      color="primary"
    />
  );
}
