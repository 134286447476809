import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import firebase from "../../config/firebase";

const musicPlayerInitialState = {
  songs: [],
  currentSong: {},
  currentAudioLoops: [],
  urlSelectedSong: undefined,
  loopsInfo: [
    { frequency: 1000, muted: false, solo: false },
    { frequency: 1000, muted: false, solo: false },
    { frequency: 1000, muted: false, solo: false },
    { frequency: 1000, muted: false, solo: false },
  ],
  currentSongIndex: 0,
  isPlaying: false,
  loading: false,
};

export const fetchLoopsAndCreateAudioURL = createAsyncThunk(
  "musicPlayer/fetchLoopsAndCreateAudio",
  async (params,) => {
    let storage = firebase.storage();
    let data = {}
    let audioLoops = [];
    try {
      for (let i = 0; i < params.refSong.loops.length; i++) {
        let loopRefUrl = await storage
          .refFromURL(params.refSong.loops[i].url)
          .getDownloadURL();
        let blob = await axios.get(loopRefUrl, {
          responseType: "blob",
        });
        let blobUrl = URL.createObjectURL(blob.data);
        audioLoops.push(blobUrl);
      }
    } catch (e) {
      console.log(e);
    }
    data = {
      audioLoops: audioLoops,
      songIndex: params.songIndex
    }
    return data;
  }
);

const musicPlayerReducer = {
  setUrlSelectedSong: (state, action) => {
    state.urlSelectedSong = action.payload;
  },
  addSongs: (state, action) => {
    state.songs = action.payload.filter((s) => s.active);
    if (state.urlSelectedSong === undefined) {
      state.currentSongIndex = 0;
      state.currentSong = state.songs[0];
    } else {
      let songIndex = undefined;
      let urlSelectedSong = undefined;
      for (let i = 0; i < state.songs.length; i++) {
        if (state.songs[i].name === state.urlSelectedSong && state.songs[i].active)
        {
          songIndex = i;
          urlSelectedSong = state.songs[i];
        }
      }
      if (urlSelectedSong !== undefined) {
        state.currentSong = urlSelectedSong;
        state.currentSongIndex = songIndex;
      } else {
        state.currentSong = state.songs[0];
        state.currentSongIndex = 0;
      }
    }
  },
  muteLoop: (state, action) => {
    state.loopsInfo[action.payload.loopIndex].muted = action.payload.muted;
  },
  setSoloLoop: (state, action) => {
    state.loopsInfo[action.payload.loopIndex].solo = action.payload.solo;
  },
  updateSoloLoops: (state, action) => {
    state.loopsInfo.forEach(el => el.solo = action.payload.solo);
  },
  updateLoopFrequency: (state, action) => {
    state.loopsInfo[action.payload.loopIndex].frequency = action.payload.frequency;
  },
  resetLoopsInfo: (state) => {
    state.loopsInfo.forEach((el) => {
      el.muted = false;
      el.solo = false;
    });
  },
  togglePlaySong: (state) => {
    state.isPlaying = !state.isPlaying;
  },
  toggleLoading: (state) => {
    state.loading = !state.loading;
  },
  nextSong: (state) => {
    if (state.currentSongIndex >= state.songs.length - 1) {
      state.currentSongIndex = 0;
      state.currentSong = Object.assign(state.currentSong, state.songs[0]);
    } else {
      state.currentSongIndex++;
      state.currentSong = Object.assign(
        state.currentSong,
        state.songs[state.currentSongIndex]
      );
    }
  },
  prevSong: (state) => {
    if (state.currentSongIndex <= 0) {
      state.currentSongIndex = state.songs.length - 1;
      state.currentSong = Object.assign(
        state.currentSong,
        state.songs[state.currentSongIndex]
      );
    } else {
      state.currentSongIndex--;
      state.currentSong = Object.assign(
        state.currentSong,
        state.songs[state.currentSongIndex]
      );
    }
  },
};

const musicPlayer = createSlice({
  name: "musicPlayer",
  initialState: musicPlayerInitialState,
  reducers: musicPlayerReducer,
  extraReducers: {
    [fetchLoopsAndCreateAudioURL.fulfilled]: (state, action) => {
      state.currentAudioLoops = action.payload.audioLoops;
      state.currentSong = state.songs[action.payload.songIndex];
      state.currentSongIndex = action.payload.songIndex;
      state.loading = false;
    },
  },
});

export const {
  addSongs,
  muteLoop,
  updateSoloLoops,
  setSoloLoop,
  updateLoopFrequency,
  resetLoopsInfo,
  togglePlaySong,
  toggleLoading,
  nextSong,
  prevSong,
  setUrlSelectedSong,
} = musicPlayer.actions;

export default musicPlayer;
