import React from 'react'
import '../../assets/styles/LandScapeModal.scss';

export default function LandScapeModal() {
    return (
        <div className="landscape-container">
            <h1 style={{color: 'white'}}>Ce projet est visible en mode portrait</h1>
        </div>
    )
}
