export default function isMobile() {
  if (
    /Android|iPhone|iPad|iPod|BlackBerry|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
}
