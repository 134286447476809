import React from "react";
import Loader from "react-loader-spinner";
import mColors from "../../utils/styleVariables";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "../../assets/styles/LoadingModal.scss";

export default function LoadingModal() {
  return (
    <div className="loader-container">
        <Loader
            type="Bars"
            color={mColors.blue}
            height={100}
            width={100}
        />
    </div>
  );
}
