import React from "react";
import styled from "@emotion/styled";
import mColors from "../../utils/styleVariables";
import { SvgIcon, makeStyles } from "@material-ui/core";
import {breakpoints} from "../../utils/constants";

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const useStyles = makeStyles({
  root: {
    fill: (props) => props.fill ? props.fill : 'black',
    "@media (max-width: 600px)": {
      width: "16px",
      height: "16px",
    },
    [mq[0]]: {
      width: "15px",
      height: "15px",
    },
    [mq[1]]: {
      width: "15px",
      height: "15px",
    },
    [mq[2]]: {
      width: "17px",
      height: "17px",
    },
    [mq[3]]: {
      width: "20px",
      height: "20px",
    },
    [mq[4]]: {
      width: "32px",
      height: "32px",
    },
  },
});

const StyledButton = styled.button((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "99px",
  backgroundColor: mColors.darkGray,
  border: "none",
  textDecoration: "none",
  cursor: "pointer",
  outline: "none",
  transform: props.large ? "scale(1.2)" : "scale(1)",
  "&:active svg path": {
    fill: props.disableSvgHover ? null : mColors.rose,
  },
  "&:focus svg path": {
    fill: props.disableSvgHover ? null : mColors.rose,
  },
  "@media (max-width: 600px)": {
    width: "35px",
    height: "35px",
  },
  [mq[0]]: {
    width: "36px",
    height: "36px",
  },
  [mq[1]]: {
    width: "40px",
    height: "40px",
  },
  [mq[2]]: {
    width: "45px",
    height: "45px",
  },
  [mq[3]]: {
    width: "50px",
    height: "50px",
  },
  [mq[4]]: {
    width: "70px",
    height: "70px",
  }
}));

export default function IconButton(props) {
  const classes = useStyles(props);

  return (
    <StyledButton
      className="iconButton"
      disableSvgHover={props.disableSvgHover}
      large={props.large}
      color={props.color}
      hoverBackgroundColor={props.hoverColor ? props.hoverColor : 'initial'}
      onClick={props.onClick}
    >
      <SvgIcon
        className={classes.root}
        component={props.component}
        viewBox={props.viewBox}
      />
    </StyledButton>
  );
}
