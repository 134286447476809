import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import {breakpoints} from "../../utils/constants";
// import PropTypes from "prop-types";

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

const useStyles = makeStyles({
  root: {
    backgroundColor: (props) => (props.active ? props.backgroundActiveColor : props.backgroundDisableColor),
    "&:hover": {
      background: (props) => (props.active ? props.backgroundActiveColor : props.backgroundDisableColor),
    },
    color: "black",
    borderRadius: "50%",
    textAlign: "center",
    verticalAlign: "middle",
    fontWeight: "bold",
    overflow: "hidden",
    fontFamily: "Source Sans Pro Bold",
    fontSize: '1.7rem',
    minWidth: "55px",
    minHeight: "55px",
    "@media (max-width: 600px)": {
      fontSize: '1rem',
      minWidth: "40px",
      minHeight: "40px",
    },
    [mq[0]]: {
    },
    [mq[1]]: {
    },
    [mq[2]]: {
    },
    [mq[3]]: {
    },
  },
});

export default function MButton(props) {
  const classes = useStyles(props);

  return (
    <Button disableFocusRipple className={classes.root} onClick={props.onClick}>
      {props.icon ? props.icon : props.text}
    </Button>
  );
}
