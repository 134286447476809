import React from "react";
import "../../assets/styles/Header.scss";
import GiftIcon from "../../components/icons/GiftIcon";
import Logo from "../../assets/images/logo_mom.svg";
import { useDispatch } from "react-redux";
import { toggleContactModal } from "../../redux/slices/modalSlice";

export default function Header(props) {
  const dispatch = useDispatch();

  return (
    <div className="app-header">
      <img
        src={Logo}
        className="header-logo"
        alt="logo"
      />
      <div
      className="contact-section"
      >
        <p className="logoDjv">DJV Selector</p>
        <div className="header-btn" onClick={() => dispatch(toggleContactModal())}>
          <GiftIcon className="header-giftIcon" />
        </div>
      </div>
    </div>
  );
}

// Header.propTypes = {
// };
