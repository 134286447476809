import React, {useState} from "react";
import Logo from "../../assets/images/logo_contact.svg";
import GiftIcon from "../icons/GiftIcon";
import { ReactComponent as CrossIcon } from "../../assets/images/delete.svg";
import "../../assets/styles/ContactModal.scss";
import IconButton from "../IconButton/IconButton";
import { useDispatch } from "react-redux";
import { toggleContactModal } from "../../redux/slices/modalSlice";
import firebase from "../../config/firebase";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

export default function ContactModal() {
  const [emailInput, setEmailInput] = useState("");
  const [isEmailValid, setEmailValid] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const dispatch = useDispatch();

  const handleEmailInput = (val) => {
    if (val.target.value === '') {
      setEmailValid(true);
    }
    setEmailInput(val.target.value);
  }

  const handleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  }

  const validateEmail = (email) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
    {
      return true;
    }
    return false;
  }

  const sendEmail = async (email) => {
    if (!validateEmail(email)) {
      setEmailValid(false);
      return
    } else {
      setEmailValid(true);
    }
    let date = new Date()
    let data = {
      email,
      date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }
    let newContactKey = firebase.database().ref().child('contacts').push().key;
    setEmailInput('');
    await firebase
        .database()
        .ref("contacts/" + newContactKey)
        .update(data).then(res => {
        }).catch(err => {
          console.log(err);
        });
    setConfirmModal(true)
  };

  return (
    <div className="contact-container">
      <div className="contact-header">
        <IconButton
          disableSvgHover={true}
          component={CrossIcon}
          viewBox="0 0 348.333 348.334"
          onClick={() => {
            dispatch(toggleContactModal());
          }}
          fill="black"
        />
      </div>
      <div className="contact-content">
        <div>
          <GiftIcon className="contact-content__giftIcon" />
          <img src={Logo} className="contact-content__logoMom" alt="logo" />
          <h1 className="info-text">Laisse moi ton mail pour en savoir plus</h1>
        </div>
        <div className="contact-input-container">
          <input
            className={!isEmailValid ? "contact-input contact-input--error" : "contact-input"}
            type="email"
            placeholder="Saisis ton mail ici"
            onChange={handleEmailInput}
            value={emailInput}
          />
          <button
            className="send-btn"
            onClick={() => sendEmail(emailInput)}
          >
            Je suis intéressé(e)
          </button>
        </div>
        <p
          className="close-btn"
          onClick={() => dispatch(toggleContactModal())}
        >
          non merci
        </p>
        <ConfirmationModal message="Votre email a bien été envoyé" visible={confirmModal} closeModal={handleConfirmModal}/>
      </div>
    </div>
  );
}

// ContactModal.propTypes = {
// };
