import React from "react";
// import PropTypes from "prop-types";
import ShareIcon from "../../assets/images/picto_partage.svg";
import {
    EmailShareButton,
    FacebookMessengerShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {useDispatch, useSelector} from "react-redux";
import "../../assets/styles/ShareModal.scss";
import IconButton from "../IconButton/IconButton";
import {ReactComponent as CrossIcon} from "../../assets/images/delete.svg";

export default function ShareModal(props) {
    const {currentSong} = useSelector((state) => state.musicPlayer);
    const dispatch = useDispatch();
    const title = "Musiconme - Studiometis Design & Musique";
    const appID = "525323051714509";
    let currentSongUrl = encodeURI(`${window.location.origin}/${currentSong.name}`);
    let fbQuote = `Ecoute DJV Selector son titre ${currentSong.name} ${currentSongUrl}`
    let twitterTitle = `Ecoute DJV Selector son titre ${currentSong.name}`
    let mailSubject = `Ecoute DJV Selector son titre ${currentSong.name}`
    let mailContent = `${currentSongUrl}`

    return (
        <div className="share-modal-container">
            <div className="share-header">
                <IconButton
                    disableSvgHover
                    component={CrossIcon}
                    viewBox="0 0 348.333 348.334"
                    onClick={() => {
                        dispatch(props.closeModal());
                    }}
                    fill="black"
                />
            </div>
            <img
                src={ShareIcon}
                className="modal-container__shareIcon shareIcon--align-center"
                alt="share icon"
            />
            <h1 className="share-title share-title--align-center">
                Partage ton titre préféré avec tes amis
            </h1>
            <div className="share-buttons-container">
                <TelegramShareButton url={currentSongUrl.toString()} title={title}>
                    <div className="share-button">Telegram</div>
                </TelegramShareButton>
                <FacebookShareButton url={currentSongUrl.toString()} quote={fbQuote}>
                    <div className="share-button">Facebook</div>
                </FacebookShareButton>
                <FacebookMessengerShareButton
                    url={currentSongUrl}
                    appId={appID}
                >
                    <div className="share-button">Messenger</div>
                </FacebookMessengerShareButton>
                <WhatsappShareButton url={currentSongUrl.toString()} title={twitterTitle}>
                    <div className="share-button">WhatsApp</div>
                </WhatsappShareButton>
                <TwitterShareButton url={currentSongUrl.toString()} title={twitterTitle}>
                    <div className="share-button">Twitter</div>
                </TwitterShareButton>
                <EmailShareButton url={mailContent} subject={mailSubject}>
                    <div className="share-button">E-mail</div>
                </EmailShareButton>
            </div>
            <p
                className="close-modal-text-btn close-modal-text-btn--align-center"
                onClick={() => dispatch(props.closeModal())}
            >
                non merci
            </p>
        </div>
    );
}
