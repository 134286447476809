import React from "react";
import {toggleShareModal} from "../../redux/slices/modalSlice";
import IconButton from "../IconButton/IconButton";
import {ReactComponent as PrevTitle} from "../../assets/images/titre_prec.svg";
import {ReactComponent as NextTitle} from "../../assets/images/titre_proc.svg";
import {ReactComponent as PlayIcon} from "../../assets/images/picto_lecture.svg";
import {ReactComponent as LikeIcon} from "../../assets/images/picto_jaime.svg";
import {ReactComponent as ShareIcon} from "../../assets/images/picto_partage.svg";
import {useDispatch, useSelector} from "react-redux";
import "../../assets/styles/MusicPlayerActions.scss";
import isMobile from "../../utils/isMobile";
import {
    fetchLoopsAndCreateAudioURL,
    nextSong,
    prevSong,
    resetLoopsInfo,
    toggleLoading,
    togglePlaySong,
} from "../../redux/slices/musicPlayerSlice";
import {ReactComponent as PauseIcon} from "../../assets/images/picto_pause.svg";
import firebase from "../../config/firebase";
import Pizzicato from 'pizzicato';

export default function MusicPlayerActions() {
    const dispatch = useDispatch();
    const {isPlaying, currentSongIndex, currentSong, songs} = useSelector(
        (state) => state.musicPlayer
    );
    let currentSongUrl = encodeURI(`${window.location.origin}/${currentSong.name}`);


    const prev = () => {
        let index = currentSongIndex;
        dispatch(toggleLoading());
        dispatch(prevSong());
        dispatch(resetLoopsInfo());
        if (isPlaying) dispatch(togglePlaySong());
        if (index <= 0) {
            index = songs.length - 1;
        } else {
            index--;
        }
        dispatch(fetchLoopsAndCreateAudioURL({refSong: songs[index], songIndex: index}));
    };

    const next = () => {
        let index = currentSongIndex;

        dispatch(toggleLoading());
        dispatch(nextSong());
        dispatch(resetLoopsInfo());
        if (isPlaying) dispatch(togglePlaySong());
        if (index >= songs.length - 1) {
            index = 0;
        } else {
            index++;
        }
        dispatch(fetchLoopsAndCreateAudioURL({refSong: songs[index], songIndex: index}));
    };

    const analyticsPlayPause = async () => {
        let onMobile = isMobile();
        let counter = null;
        let mobileCounter = null;
        let desktopCounter = null;
        let songKey = undefined;

        // Search song key
        await firebase.database().ref('/analytics/playPause/').once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                if (childSnapshot.val().name === currentSong.name) {
                    songKey = childSnapshot.key;
                    counter = childSnapshot.val().counter;
                    mobileCounter = childSnapshot.val().mobileCounter;
                    desktopCounter = childSnapshot.val().desktopCounter;
                }
            })
        });

        if (songKey === undefined) {
            // Create new key if we dont find one with the currentSong name
            let newPlayKey = firebase.database().ref('analytics').child('playPause').push().key;
            const data = {
                name: currentSong.name,
                counter: 1,
                mobileCounter: onMobile ? 1 : 0,
                desktopCounter: onMobile ? 0 : 1
            }
            await firebase
                .database()
                .ref("analytics/playPause/" + newPlayKey)
                .update(data).catch(err => console.log(err));
        } else {
            // Update the existing counter value
            await firebase
                .database()
                .ref("analytics/playPause/" + songKey.toString())
                .update({
                    name: currentSong.name,
                    counter: counter + 1,
                    mobileCounter: onMobile ? mobileCounter + 1 : mobileCounter,
                    desktopCounter: onMobile ? desktopCounter : desktopCounter + 1,
                }).catch(err => console.log(err));
        }
    };

    const togglePlayPause = async () => {
        let context = Pizzicato.context;
        let source = context.createBufferSource();
        source.buffer = context.createBuffer(1, 1, 22050);
        source.connect(context.destination);
        source.start();
        dispatch(togglePlaySong());
        await analyticsPlayPause();
    };

    const likeSong = async () => {
        let onMobile = isMobile();
        let counter = null;
        let mobileCounter = null;
        let desktopCounter = null;
        let songKey = undefined;

        // Search song key
        await firebase.database().ref('/analytics/likes/').once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                if (childSnapshot.val().name === currentSong.name) {
                    songKey = childSnapshot.key;
                    counter = childSnapshot.val().counter;
                    mobileCounter = childSnapshot.val().mobileCounter;
                    desktopCounter = childSnapshot.val().desktopCounter;
                }
            })
        });

        if (songKey === undefined) {
            // Create new key if we dont find one with the currentSong name
            let newKey = firebase.database().ref('analytics').child('likes').push().key;
            const data = {
                name: currentSong.name,
                counter: 1,
                mobileCounter: onMobile ? 1 : 0,
                desktopCounter: onMobile ? 0 : 1
            }
            await firebase
                .database()
                .ref("analytics/likes/" + newKey)
                .update(data).catch(err => console.log(err));
        } else {
            // Update the existing counter value
            await firebase
                .database()
                .ref("analytics/likes/" + songKey.toString())
                .update({
                    name: currentSong.name,
                    counter: counter + 1,
                    mobileCounter: onMobile ? mobileCounter + 1 : mobileCounter,
                    desktopCounter: onMobile ? desktopCounter : desktopCounter + 1,
                }).catch(err => console.log(err));
        }
    };

    return (
        <div className="player-actions-container">
            <IconButton
                className="share-btn"
                component={ShareIcon}
                viewBox="0 0 31.491 31.491"
                onClick={() => {
                    if (navigator.share && isMobile()) {
                        navigator
                            .share({
                                title: "Musiconme - Studiometis Design & Musique",
                                text: `Ecoute DJV Selector son titre ${currentSong.name}`,
                                url: `${currentSongUrl}`
                            })
                            .then(() => console.log("Successful share"))
                            .catch((error) => console.log("Error sharing", error));
                    } else dispatch(toggleShareModal());
                }}
            />

            <div className="player-actions">
                <IconButton
                    component={PrevTitle}
                    viewBox="0 0 32.548 32.52"
                    onClick={prev}
                />
                {isPlaying ? (
                    <IconButton
                        component={PauseIcon}
                        large={true}
                        viewBox="0 0 28.445 36.544"
                        onClick={togglePlayPause}
                    />
                ) : (
                    <IconButton
                        component={PlayIcon}
                        large={true}
                        viewBox="0 0 28.445 36.544"
                        onClick={togglePlayPause}
                    />
                )}
                {/* <div
          className="play-pause-btn"
          onClick={togglePlayPause}
        >
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </div> */}
                <IconButton
                    component={NextTitle}
                    viewBox="0 0 32.548 32.519"
                    onClick={next}
                />
            </div>
            <IconButton
                className="like-btn"
                component={LikeIcon}
                viewBox="0 0 31.491 31.491"
                onClick={likeSong}
            />
        </div>
    );
}

// MusicPlayerActually.propTypes = {

// }
