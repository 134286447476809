import React from 'react';
import '../../assets/styles/ConfirmationModal.scss';

const ConfirmationModal = (props) => {
    const visible = props.visible;

    return (
        <div className={visible ? "confirm-modal confirm-modal--visible" : "confirm-modal"}>
            <div className="confirm-modal__content">
                <p className="content__message">{props.message}</p>
                <button className="confirm-btn" onClick={props.closeModal}>Continuer</button>
            </div>
        </div>
    )
}

export default ConfirmationModal;
