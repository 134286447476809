import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import {useEffect, useState} from "react";
import "./assets/styles/Musiconme.scss";
import ContactModal from "./components/ContactModal/ContactModal";
import Header from "./components/Header/Header";
import MusicDisplay from "./components/MusicDisplay/MusicDisplay";
import MusicPlayerActions from "./components/MusicPlayerActions/MusicPlayerActions";
import ShareModal from "./components/ShareModal/ShareModal";
import {toggleShareModal} from "./redux/slices/modalSlice";
import {useDispatch, useSelector} from "react-redux";
import isMobile from "./utils/isMobile";
import LandScapeModal from "./components/LandScapeModal/LandScapeModal";
import firebase from "./config/firebase";
import {
    addSongs,
    fetchLoopsAndCreateAudioURL,
    setUrlSelectedSong,
    toggleLoading
} from "./redux/slices/musicPlayerSlice";
import LoopSliders from "./components/LoopSliders/LoopSliders";
import PresentationModal from "./components/PresentationModal/PresentationModal";
import LoadingModal from "./components/LoadingModal/LoadingModal";
import {useParams} from "react-router-dom";

const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#109dff",
        },
    },
    shape: {
        borderRadius: 8,
    },
    typography: {
        fontFamily: "Source Sans Pro",
    },
    props: {
        MuiButtonBase: {
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
    },
});

function Musiconme() {
    const dispatch = useDispatch();
    const {isShareModalOpen, isContactModalOpen} = useSelector(
        (state) => state.modals
    );
    const {loading} = useSelector((state) => state.musicPlayer);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [isLandScape, setIsLandScape] = useState(false);
    const [readyModal, setReadyModal] = useState(false);
    const {songName} = useParams();

    const toggleReadyModal = () => {
        setReadyModal(true);
    };

    const onResize = () => {
        setInnerWidth(window.innerWidth);
        setInnerHeight(window.innerHeight);
    };

    useEffect(() => {
        if (songName !== undefined) {
            dispatch(setUrlSelectedSong(songName));
        }

    }, [songName, dispatch]);

    useEffect(() => {
        if (isMobile()) window.addEventListener("resize", onResize);

        return () => {
            if (isMobile()) window.removeEventListener("resize", onResize);
        };
    });

    useEffect(() => {
        if (innerWidth > innerHeight && isMobile()) {
            setIsLandScape(true);
        } else {
            setIsLandScape(false);
        }
    }, [innerHeight, innerWidth]);

    useEffect(() => {
        let db = firebase.database();
        let ref = db.ref("songs");
        const getSongs = async (ref, refSong) => {
            await ref.once("value", (snapshot) => {
                let filterSongs = snapshot.val().filter(song => song.active)
                let rand = Math.floor(Math.random() * Math.floor(filterSongs.length));
                dispatch(addSongs(snapshot.val()));
                if (songName === undefined) {
                    refSong = filterSongs[rand];
                } else {
                    let songIndex = 0;
                    let song = undefined;
                    filterSongs.forEach((s, index) => {
                        if (s.name === songName) {
                            songIndex = index;
                            song = s;
                        }
                    })
                    console.log(song);
                    if (song !== undefined) {
                        refSong = song;
                        rand = songIndex;
                    } else {
                        refSong = filterSongs[rand];
                    }
                }
                let data = {
                    refSong: refSong,
                    songIndex: rand,
                }
                dispatch(fetchLoopsAndCreateAudioURL(data));
            });
        }
        if (readyModal) {
            let refSong = undefined;
            dispatch(toggleLoading());
            getSongs(ref, refSong);
        }
    }, [dispatch, readyModal, songName]);

    return (
        <ThemeProvider theme={theme}>
            {!readyModal ? (
                <PresentationModal toggleReadyModal={toggleReadyModal}/>
            ) : null}
            {loading ? <LoadingModal/> : null}
            {isLandScape ? <LandScapeModal/> : null}
            {isShareModalOpen ? <ShareModal closeModal={toggleShareModal}/> : null}
            {isContactModalOpen ? <ContactModal/> : null}
            <div className="app-bg">
                <div className="app-container">
                    <Header/>
                    <LoopSliders/>
                    <div className="display-actions-container">
                        <MusicDisplay/>
                        <MusicPlayerActions/>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default Musiconme;
