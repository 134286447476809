import { createSlice } from "@reduxjs/toolkit";

const modalInitialState = {
  isContactModalOpen: false,
  isShareModalOpen: false,
};

const modalReducer = {
  toggleContactModal: (state) => {
    state.isContactModalOpen = !state.isContactModalOpen;
  },
  toggleShareModal: (state) => {
    state.isShareModalOpen = !state.isShareModalOpen;
  },
};

const modalSlice = createSlice({
  name: "modals",
  initialState: modalInitialState,
  reducers: modalReducer,
});

export const { toggleShareModal, toggleContactModal } = modalSlice.actions;

export default modalSlice;