import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import createWebStorage from "redux-persist/es/storage/createWebStorage";
import musicPlayer from "./slices/musicPlayerSlice";
import modalSlice from "./slices/modalSlice";

const storage = createWebStorage("local");

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ['modals', 'musicPlayer'],
};

const rootReducer = combineReducers({
  musicPlayer: musicPlayer.reducer,
  modals: modalSlice.reducer,
});

const pReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: pReducer,
  middleware: getDefaultMiddleware({
    thunk: true,
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,],
    },
  }),
  devTools: true,
});

const persistor = persistStore(store);

const defaultStoreExport = {
  store,
  persistor,
};

export default defaultStoreExport;
